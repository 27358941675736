<template>
  <div class="pa-3">
    <div class="d-flex justify-center mb-2">
      <div class="col-sm-12 col-md-5 col-lg-3 d-flex flex-column">
        <h2 v-text="plan.name"></h2>
      </div>
    </div>
    <div class="d-flex justify-center">
      <div class="col-sm-12 col-md-5 col-lg-3 d-flex flex-column">
        <v-img v-if="plan.appPlaneImageUrl" :src="imageUrl"></v-img>
        <v-img v-else :src="notImage"></v-img>
      </div>
    </div>
  </div>
</template>

<script>
import loaderMixin from "@/mixins/loaderMixin";

export default {
  name: "plan-fair",
  mixins: [loaderMixin],
  computed: {
    plan() {
      return this.$store.getters["plans/plan"];
    },
    imageUrl() {
      return (
        process.env.VUE_APP_API_AUTH_IMAGES_URL +
        this.$store.getters["plans/plan"].appPlaneImageUrl
      );
    },
    notImage() {
      return require(`../../../../public/img/icons/${process.env.VUE_APP_FAIR_RESOURCES_DIRECTORY}/not-image.jpg`);
    },
  },
};
</script>
